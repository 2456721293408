import Web3 from 'web3';
import store from '../store';
import MinterContract from '../../contract/MinterContract.json';

const loadContractRequest = () => {
   return {
      type: 'LOAD_MINTER_CONTRACT_REQUEST',
   };
};

const loadContractSuccess = (payload) => {
   return {
      type: 'LOAD_MINTER_CONTRACT_SUCCESS',
      payload: payload,
   };
};

const loadContractFailed = (payload) => {
   return {
      type: 'LOAD_MINTER_CONTRACT_FAILED',
      payload: payload,
   };
};

const walletConnectionFailure = (payload) => {
   return {
      type: 'WALLET_CONNECTION_FAILURE',
      payload: payload,
   };
};

const fetchCollectionSupplyRequest = () => {
   return {
      type: 'FETCH_COLLECTION_SUPPLY_REQUEST',
   };
};

const fetchCollectionSupplySuccess = (payload) => {
   return {
      type: 'FETCH_COLLECTION_SUPPLY_SUCCESS',
      payload: payload,
   };
};

const fetchCollectionSupplyFailed = (payload) => {
   return {
      type: 'FETCH_COLLECTION_SUPPLY_FAILED',
      payload: payload,
   };
};

const fetchMyNFTs = () => {
   return {
      type: 'FETCH_COLLECTION_MY_NFTS_REQUEST',
   };
};

const fetchMyNFTsSuccess = (payload) => {
   return {
      type: 'FETCH_COLLECTION_MY_NFTS_SUCCESS',
      payload: payload,
   };
};

const fetchMyNFTsFailed = (payload) => {
   return {
      type: 'FETCH_COLLECTION_MY_NFTS_FAILED',
      payload: payload,
   };
};

export const loadMinterContract = (CONTRACT_ADDRESS) => {
   return async (dispatch) => {
      dispatch(loadContractRequest());
      const { ethereum } = window;
      const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

      if (metamaskIsInstalled) {
         let web3 = new Web3(ethereum);
         try {
            const accounts = await ethereum.request({
               method: 'eth_requestAccounts',
            });
            const networkId = await ethereum.request({
               method: 'net_version',
            });

            /* eslint eqeqeq: 0 */
            if (networkId == 250 || 4002) {
               const MinterContractObj = new web3.eth.Contract(
                  MinterContract,
                  CONTRACT_ADDRESS
               );
               dispatch(
                  loadContractSuccess({
                     account: accounts[0],
                     contract: MinterContractObj,
                     web3: web3,
                  })
               );

               dispatch(fetchCirculatingSupply());
            } else {
               alert(`Please Connect To Fantom Network! [Chain ID: 250]`);

               dispatch(
                  loadContractFailed(
                     `Please Connect To Fantom Network! [Chain ID: 250]`
                  )
               );
            }
         } catch (err) {
            alert('Please Log into metamsk!');
            dispatch(walletConnectionFailure('Something went wrong.'));
         }
      } else {
         alert('Please Install Metamask on your device.');
         dispatch(walletConnectionFailure('Install Metamask.'));
      }
   };
};

export const fetchCirculatingSupply = () => {
   return async (dispatch) => {
      dispatch(fetchCollectionSupplyRequest());

      try {
         let currentSupply = await store
            .getState()
            .minterContract?.contract?.methods.totalSupply()
            .call();

         dispatch(
            fetchCollectionSupplySuccess({
               circulatingSupply: currentSupply,
            })
         );
      } catch (err) {
         dispatch(
            fetchCollectionSupplyFailed('Could not load data from contract.')
         );
      }
   };
};
