import { Box, Link, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import Digitization from "../../assets/videos/Digitization.mp4";
import NFT from "../../assets/videos/NFT game.mp4";
import Quantum from "../../assets/videos/Quantum History.mp4";
import newsImg from "../../assets/img/news_img1.png";
import nft1 from "../../assets/img/nft1.jpg";
import nft2 from "../../assets/img/nfts/NFT1.jpg";
import SpanTitle from "../Common/SpanTitle";
import CheckIcon from "@mui/icons-material/Check";
import bgImf from "../../assets/img/news_bg.png";
import opReal from "../../assets/img/nfts/Oviraptor_real.jpg";
import opVirtual from "../../assets/img/nfts/oviraptor_virtual.png";
import gold from "../../assets/img/nfts/gold.png";
import sharkTooth from "../../assets/img/nfts/shark_tooth.png";
import ammonite from "../../assets/img/nfts/ammonite.png";
import pyrite from "../../assets/img/nfts/pyrite.png";
import video1 from "../../assets/videos/nfts/Spinosaurus_Claw.gif";
import video2 from "../../assets/videos/nfts/Goggotte.gif";
import video3 from "../../assets/videos/nfts/meteorito.gif";

const News = () => {
  const { t } = useTranslation(["news"]);
  const list = [
    {
      title: t("digitization"),
      video: "fBDZEtCLaUs",
    },
    {
      title: t("nft"),
      video: "0pURQ-TMVoI",
    },
    {
      title: t("history"),
      video: "wS8WcU1Aq-c",
    },
  ];
  return (
    <Container
      id="news"
      sx={{
        color: "#fff",
        marginBottom: { sm: 2, xs: 1, md: 5 },
        paddingTop: { sm: 3, xs: 3, md: 20 },
      }}
    >
      <Typography sx={{ typography: { sm: "h3", xs: "h4" } }}>
        {t("threepilars")}
      </Typography>
      {/* three pillars */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        mt={2}
      >
        {list?.map((val, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: { sm: "30%", xs: "100%", md: "30%" },
                borderRight: {
                  sm: `${
                    index !== list.length - 1 ? " 5px outset orange" : "none"
                  }`,
                  xs: "none",
                  md: `${
                    index !== list.length - 1 ? "5px outset orange" : "none"
                  }`,
                },
                borderRadius: 2,
              }}
              pb={2}
              mb={3}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  mr={1}
                  sx={{
                    width: "3%",
                    height: "2px",
                    backgroundColor: "#fff",
                    borderRadius: "10%",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "30px",
                    fontWeight: 100,
                    marginBottom: "3px",
                  }}
                >
                  {val?.title.toUpperCase()}
                </Typography>
              </Box>
              <iframe
                title={val?.title.toUpperCase()}
                frameborder="0"
                allowfullscreen
                src={`https://www.youtube.com/embed/${val?.video}?autoplay=1&mute=1&controls=0&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${val?.video}`}
              ></iframe>
            </Box>
          );
        })}
      </Box>

      {/* where metaverse */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mt={{ sm: 10, xs: 10, md: 20 }}
        mb={{ sm: 5, xs: 5, md: 15 }}
      >
        <Box width={{ sm: "100%", md: "55%" }}>
          <Typography sx={{ typography: { sm: "h3", xs: "h4" } }}>
            {t("wheremetaverse")}
          </Typography>
          <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
            {t("wheremetaversetitle2")}
          </Typography>
          <Typography
            variant="description"
            component="p"
            mt={{ xs: 2, sm: 2, md: 0 }}
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
            }}
          >
            {" "}
            {t("wheremetaversedesc1")}{" "}
          </Typography>
          <Typography
            variant="description"
            component="p"
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
            }}
          >
            {" "}
            {t("wheremetaversedesc2")}{" "}
          </Typography>
        </Box>
        <Box width={{ sm: "100%", md: "40%" }} mt={{ xs: 5, md: 0 }}>
          <img width="100%" height="100%" src={newsImg} alt="home" />
        </Box>
      </Box>

      {/* NFT Collection 1 */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "start",
        }}
        mt={{ sm: 10, xs: 10, md: 20 }}
        mb={{ sm: 5, xs: 5, md: 15 }}
      >
        <Box width={{ sm: "100%", md: "30%" }} mt={{ xs: 5, md: 0 }}>
          <img width="100%" height="100%" src={nft1} alt="nft1" />
        </Box>
        <Box width={{ sm: "100%", md: "65%" }}>
          <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
            {t("nfttitle1").toUpperCase()}
          </Typography>
          <Typography sx={{ typography: { sm: "h5", xs: "h6" } }}>
            {t("nftsecondarytitle1").toUpperCase()}
          </Typography>
          <Typography
            variant="description"
            component="p"
            mt={{ xs: 2, sm: 2, md: 0 }}
            mb={{ xs: 2, sm: 2, md: 5 }}
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
            }}
          >
            {" "}
            {t("nftdesc1")}{" "}
          </Typography>
          <SpanTitle title={t("nftspantitle1")} />
          <Typography
            variant="description"
            component="p"
            mt={{ xs: 2, sm: 2, md: 5 }}
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
            }}
          >
            {" "}
            {t("nftdesc2")}{" "}
          </Typography>
        </Box>
      </Box>

      {/* NFT Collection 2 */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "start",
        }}
        mt={{ sm: 10, xs: 10, md: 20 }}
      >
        <Box width={{ sm: "100%", md: "30%" }} mt={{ xs: 5, md: 1 }}>
          <img width="100%" height="100%" src={nft2} alt="nft1" />
          <Typography
            variant="description"
            component="p"
            sx={{
              lineHeight: "1.5",
            }}
          >
            {" "}
            {t("newSpanTitel")}{" "}
          </Typography>
        </Box>
        <Box width={{ sm: "100%", md: "65%" }}>
          <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
            {t("nfttitle1").toUpperCase()}
          </Typography>
          <Typography sx={{ typography: { sm: "h5", xs: "h6" } }}>
            {t("nftsecondarytitle1").toUpperCase()}
          </Typography>
          <Typography
            variant="description"
            component="p"
            mt={{ xs: 2, sm: 2, md: 0 }}
            mb={2}
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
            }}
          >
            {" "}
            {t("newFtDesc2")}{" "}
          </Typography>
          <Typography
            variant="description"
            component="p"
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
            }}
          >
            {" "}
            {t("newFtDesc2Part1")}{" "}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "40%", height: "150px" }}>
              <img
                width="100%"
                height="100%"
                src={opReal}
                alt="opReal"
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Box sx={{ width: "40%", height: "150px" }}>
              <img
                width="100%"
                height="100%"
                src={opVirtual}
                alt="opVirtual"
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Box>
          <Typography
            variant="description"
            component="p"
            mt={1}
            sx={{
              fontSize: "15px",
              textAlign: "center",
            }}
          >
            {t("opText")}
          </Typography>
        </Box>
      </Box>

      <Box mb={{ sm: 5, xs: 5, md: 15 }}>
        <Typography
          variant="description"
          component="p"
          mt={{ xs: 2, sm: 2, md: 5 }}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(`newDetails1`)}{" "}
        </Typography>
        <Typography
          variant="description"
          component="p"
          mt={2}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(`newDetails2`)}{" "}
        </Typography>
        {[...Array(7).keys()].map((value, index) => {
          return (
            <>
              <Typography
                variant="description"
                key={value + index}
                component="p"
                mt={value === 1 ? { xs: 2, sm: 2, md: 5 } : 2}
                sx={{
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                }}
              >
                {" "}
                <CheckIcon
                  sx={{ fontSize: 15, fontWeight: "bold", marginRight: 3 }}
                />
                {t(`benefit${value + 1}`)}
              </Typography>
              {value === 0 && (
                <Box
                  mt={2}
                  mb={{ xs: 10, sm: 10, md: 0 }}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Box
                    mb={2}
                    sx={{ width: "20%", height: "150px", textAlign: "center" }}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={gold}
                      alt="opReal"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography
                      variant="description"
                      component="p"
                      mt={2}
                      sx={{
                        lineHeight: "1.85",
                      }}
                    >
                      Gold
                    </Typography>
                  </Box>
                  <Box
                    sx={{ width: "20%", height: "150px", textAlign: "center" }}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={sharkTooth}
                      alt="opReal"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography
                      variant="description"
                      component="p"
                      mt={2}
                      sx={{
                        lineHeight: "1.85",
                      }}
                    >
                      Shark Tooth
                    </Typography>
                  </Box>
                  <Box
                    sx={{ width: "20%", height: "150px", textAlign: "center" }}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={ammonite}
                      alt="opReal"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography
                      variant="description"
                      component="p"
                      mt={2}
                      sx={{
                        lineHeight: "1.85",
                      }}
                    >
                      Ammonite
                    </Typography>
                  </Box>
                  <Box
                    sx={{ width: "20%", height: "150px", textAlign: "center" }}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={pyrite}
                      alt="opReal"
                      style={{ objectFit: "contain" }}
                    />
                    <Typography
                      variant="description"
                      component="p"
                      mt={2}
                      sx={{
                        lineHeight: "1.85",
                      }}
                    >
                      Pyrite
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          );
        })}
        <Typography
          variant="description"
          component="p"
          mt={{ xs: 2, sm: 2, md: 5 }}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(`newDetails3`)}{" "}
        </Typography>
        <Link
          href="https://opensea.io/Quantum-Museum"
          target="_blank"
          sx={{
            textDecoration: "none",
            color: "white",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {" "}
          https://opensea.io/Quantum-Museum{" "}
        </Link>
        <Typography
          variant="description"
          component="p"
          mt={{ xs: 2, sm: 2, md: 5 }}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(`newDetails4`)}{" "}
        </Typography>
        <Box mt={3} textAlign="center">
          <img
            width="250px"
            height="250px"
            src={video3}
            alt="video3"
            style={{ margin: "0 auto" }}
          />
          <Typography
            variant="description"
            component="p"
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
              textAlign: "center",
              color: "white",
            }}
          >
            {t(`video1Title`)}{" "}
          </Typography>
        </Box>
        <Typography
          variant="description"
          component="p"
          mt={2}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
          }}
        >
          {t(`auctionsDesc`)}
          <Link
            href="https://onlineonly.christies.com/s/science-natural-history/sikhote-alin-meteorite-natural-hole-195/130701"
            target="_blank"
            sx={{
              textDecoration: "none",
              display: "inline",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Sotheby's
          </Link>
          {" and "}
          <Link
            href="https://www.sothebys.com/en/buy/auction/2022/meteorites-select-specimens-from-the-moon-mars-vesta-and-more/campo-del-cielo-meteorite-with-natural-hole"
            target="_blank"
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Christies
          </Link>
        </Typography>

        <Box mt={3} textAlign="center">
          <img width="250px" height="250px" src={video2} alt="video2" />
          <Typography
            variant="description"
            component="p"
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
              textAlign: "center",
              color: "white",
            }}
          >
            {t(`video2Title`)}{" "}
          </Typography>
        </Box>
        <Typography
          variant="description"
          component="p"
          mt={2}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
          }}
        >
          {t(`auctionsDesc2`)}{" "}
          <Link
            href="https://www.sothebys.com/en/buy/auction/2020/natural-history-including-fossils-minerals-meteorites/a-gogotte-formation-2"
            target="_blank"
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Sotheby's
          </Link>
          {" and "}
          <Link
            href="https://onlineonly.christies.com/s/sculpted-nature-fossils-minerals-meteorites/gogotte-formation-16/84113"
            target="_blank"
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Christies
          </Link>
        </Typography>
        <Box mt={3} textAlign="center">
          <Typography
            variant="description"
            component="p"
            sx={{
              fontSize: "18px",
              lineHeight: "1.85",
              textAlign: "center",
              color: "white",
            }}
          >
            {t(`video3Title`)}{" "}
          </Typography>
          <img width="250px" height="250px" src={video1} alt="video1" />
        </Box>
        <Typography
          variant="description"
          component="p"
          mt={2}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
          }}
        >
          {t(`auctionsDesc3`)}{" "}
          <Link
            href="https://onlineonly.christies.com/s/sculpted-nature-fossil-minerals-meteorites/dinosaur-claw-91/150608"
            target="_blank"
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Sotheby's
          </Link>
          {" and "}
          <Link
            href="https://www.sothebys.com/en/buy/auction/2020/natural-history-including-fossils-minerals-meteorites/a-rare-dinosaur-claw"
            target="_blank"
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Christies
          </Link>
        </Typography>
        <Typography
          variant="description"
          component="p"
          mt={2}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(`newDetails5`)}{" "}
        </Typography>
        <Typography
          variant="description"
          component="p"
          mt={2}
          sx={{
            fontSize: "18px",
            lineHeight: "1.85",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(`newDetails6`)}{" "}
        </Typography>
      </Box>
    </Container>
  );
};

export default News;
