import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;

   @media (max-width: 1400px) {
      justify-content: space-between;
   }

   @media (max-width: 775px) {
      flex-direction: column;
      justify-content: space-between;
   }
`;

export const ContentWrapper = styled.div`
   position: relative;
   display: flex;
   justify-content: space-around;
   align-items: ${({ alignItems }) => (alignItems ? 'flex-start' : 'center')};
   flex-direction: ${(props) => props.flexDirection || 'row'};
   padding: ${(props) => props.padding || '0px'};
`;

export const ShineCover = styled.div`
   margin: ${(props) => props.margin || '0px'};
   width: fit-content;
   height: fit-content;
   display: flex;
   align-items: center;
   border: 2px solid #a17230;
   border-radius: 5px;
`;

export const ContentBox = styled.div`
   width: 10rem;
   height: 9rem;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   backdrop-filter: blur(2px);
   font-size: ${(props) => props.fontSize || '2rem;'};
   text-align: center;
   font-weight: 600;

   @media (max-width: 475px) {
      height: 6.8rem;
      width: 6.8rem;
      font-size: 0.8rem;
   }
`;

export const Divider = styled.div`
   height: 8rem;
   width: 0.1px;
   border: 2px solid #a17230;
   border-radius: 5px;

   @media (max-width: 475px) {
      height: 5.8rem !important;
      font-size: 1.8rem;
   }
`;

export const MintButton = styled.button`
   ${({ active }) =>
      active === 0
         ? 'cursor: not-allowed !important; opacity: 0.5 ;'
         : 'cursor: pointer; opacity: 1;'};
   width: 20.5rem;
   height: 3rem;
   margin-top: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   color: white;
   font-size: 1.5em;
   transition: 0.5s;

   backdrop-filter: blur(2px);
   background: rgba(255, 255, 255, 0);

   border: 2px solid #a17230;
   border-radius: 5px;

   &:active {
      transform: scale(0.9);
   }

   &:hover {
      opacity: 0.7;
   }

   @media (max-width: 475px) {
      height: 2.5rem;
      width: 14.3rem;
      font-size: 1.2rem;
   }
`;

export const ButtonWrapper = styled.div`
   height: 50%;
   margin-bottom: 120px;
   align-items: center;
   width: 100%;
   display: flex;
   justify-content: center;
`;

export const SpinnerWrapper = styled.div`
   margin-bottom: 120px;
   height: 50%;
   align-items: center;
   display: flex;
`;

const shine = keyframes`
10% {
  opacity: 1;
  top: -30%;
  left: -30%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}
100% {
  opacity: 0;
  top: -30%;
  left: -30%;
  transition-property: left, top, opacity;
}
`;

export const GlassButton = styled.button`
   position: relative;
   /* width: 80px; */
   width: ${(props) => props.width || '60px'};
   /* height: 80px; */
   height: ${(props) => props.height || '60px'};
   display: inline-block;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   color: white;
   font-size: 2em;
   border: 2px solid #a17230;
   backdrop-filter: blur(2px);
   transition: 0.5s;
   overflow: hidden;
   background: rgba(255, 255, 255, 0);
   margin: 5px;
   text-align: center;
   line-height: 50px;
   font-family: sans-serif;

   border-radius: 5px !important;

   @media (max-width: 475px) {
      height: 50px !important;
      width: 50px !important;
   }

   &:after {
      animation: ${shine} 5s ease-in-out infinite;
      animation-fill-mode: forwards;
      content: '';
      position: absolute;
      top: -110%;
      left: -210%;
      width: 200%;
      height: 200%;
      opacity: 0;
      transform: rotate(30deg);

      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
         to right,
         rgba(255, 255, 255, 0.13) 0%,
         rgba(255, 255, 255, 0.13) 77%,
         rgba(255, 255, 255, 0.5) 92%,
         rgba(255, 255, 255, 0) 100%
      );
   }

   &:active:after {
      opacity: 0.1;
   }

   &:active {
      transform: scale(0.8);
   }
   &:hover {
      opacity: 0.7;
   }
`;
