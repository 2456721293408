const config = {
   CONTRACT_ADDRESS: '0xe107A65eE6D24B4997DEE2e131271e05d9FB7a7D',
   NETWORK_EXPLORER: 'https://ftmscan.com/tx',
   GAS_LIMIT: '750000',
   MAX_SUPPLY: 4444,
   NETWORK_ID: 250,
   NETWORK_NAME: 'Fantom Opera',
   NETWORK_SYMBOL: 'FTM',
   PUBLIC_SALE_PRICE: 200,
};

export default config;
