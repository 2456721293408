import React, { useState, useEffect } from 'react';
import { connect, disconnect } from '../../../redux/user/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

const ConnectButton = ({ display }) => {
   const [connButtonText, setConnButtonText] = useState('CONNECT WALLET');
   const dispatch = useDispatch();
   const user = useSelector((state) => state.user);

   const account = user.account;
   useEffect(() => {
      if (account !== '') {
         if (account) {
            setConnButtonText(
               account.substring(0, 4) + '...' + account.substring(38, 42)
            );
         } else {
            setConnButtonText('Connect');
            dispatch(disconnect());
         }
      }
   }, [user.account, dispatch, account]);

   const handleConnectClick = (e) => {
      e.preventDefault();
      if (user.account === '') {
         dispatch(connect());
      } else {
         if (account) {
            alert(
               `You are already connected with wallet ${account}. If you wish to change it please use metamask.`
            );

            alert(
               `You are already connected with wallet ${account}. If you wish to change it please use metamask.`
            );
         } else {
            dispatch(connect());
         }
      }
   };

   return (
      <Button
         onClick={handleConnectClick}
         variant='contained'
         sx={{
            display: display,
            backgroundColor: '#10cb6c',
            height: '50px',
            width: '180px',
            border: '2px solid transparent',
            transition: 'all .3s ease',
            '&:hover': {
               backgroundColor: '#20c997',
               boxShadow: '0 3px 32px #00e7ea8a',
            },
            margin: 2,
         }}
      >
         {connButtonText}
      </Button>
   );
};

// const ConnectButton = ({ display }) => {
//    return (
//       <Button
//          variant='contained'
//          sx={{
//             display: display,
//             backgroundColor: '#10cb6c',
//             height: '50px',
//             width: '180px',
//             border: '2px solid transparent',
//             transition: 'all .3s ease',
//             '&:hover': {
//                backgroundColor: '#20c997',
//                boxShadow: '0 3px 32px #00e7ea8a',
//             },
//             margin: 2,
//          }}
//       >
//          Connect Wallet
//       </Button>
//    );
// };

export default ConnectButton;
