import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
   return (
      <CircularProgress size={15} thickness={5} style={{ color: '#ffffff' }} />
   );
};

export default Spinner;
