import { Box, Container, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import SpanTitle from "../Common/SpanTitle";
import { useTranslation } from "react-i18next";
import jonatan from "../../assets/img/Jonatan.jpg";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Twitter } from "@mui/icons-material";
import teamList from "./teamList";
import bgImg from "../../assets/img/team-bg.png";

const Team = () => {
  const { t } = useTranslation(["team"]);
  return (
    <Container
      id="team"
      sx={{
        color: "#fff",
        marginBottom: { sm: 2, xs: 1, md: 5 },
        paddingTop: { sm: 3, xs: 3, md: 20 },
      }}
    >
      <SpanTitle title="Our Brain" />
      <Typography mt={3} mb={5} sx={{ typography: { sm: "h3", xs: "h4" } }}>
        AWESOME TEAM
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {teamList.map((value, index) => {
          return (
            <Box key={index} m={3} textAlign="center">
              <img
                width="200px"
                height="200px"
                style={{ objectFit: "contain" }}
                src={value?.src}
                alt="profile"
              />
              <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
                {value?.name}
              </Typography>
              <Typography
                sx={{ typography: { sm: "h5", xs: "h6" }, fontStyle: "italic" }}
              >
                {value?.designation}
              </Typography>
              <Typography
                variant="description"
                component="p"
                mt={2}
                sx={{
                  fontSize: "18px",
                  lineHeight: "1.85",
                }}
              >
                {value?.about}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default Team;
