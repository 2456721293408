import { Box, Typography } from "@mui/material";
import React from "react";

const roadMapInfo = [
  {
    timeLaps: "JANUARY-MARCH 2022",
    desc: [
      "Team formation Conceptual creation of the platform  ",
      "Market research and launch",
      "Strategic development of the business plan The platform introduction",
    ],
  },
  {
    timeLaps: "MARCH-MAY 2022",
    desc: [
      "Selection and digitization of the items for the NFT game and art gallery. ",
      "Website development",
    ],
  },
  {
    timeLaps: "JUNE-AUGUST 2022",
    desc: [
      "1st NFT collection development and launch ",
      "Tokenomics development Private corporate fund",
    ],
  },
  {
    timeLaps: "SEPTEMBER-DECEMBER 2022",
    desc: [
      "Metaverse creation: Art gallery creation ",
      "Metaverse creation: Alpha NFT game launch",
    ],
  },
  {
    timeLaps: "JANUARY-MARCH 2023",
    desc: [
      "Metaverse creation: Virtual Museum creation ",
      "Metaverse creation: Beta NFT game launch",
      " Quantum ICO launch ",
      "Real life Quantum Museum construction begins",
    ],
  },
  {
    timeLaps: "Q2 2023 AND ONWARD",
    desc: ["Launch fourth marketing campaign ", "product release v1.0"],
  },
];

const Stepper = () => {
  return (
    <>
      {roadMapInfo?.map((value, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
          >
            <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
              {value?.timeLaps}
            </Typography>
            {value?.desc.map((value, index) => {
              return (
                <Typography
                  key={index}
                  variant="description"
                  component="p"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "1.85",
                  }}
                >
                  {value}
                </Typography>
              );
            })}

            {index !== roadMapInfo.length - 1 && (
              <Box
                sx={{
                  width: 2,
                  backgroundColor: "#00cde4",
                  height: 100,
                  margin: "0 auto",
                }}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

export default Stepper;
