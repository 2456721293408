import React, { Fragment, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from '../../redux/user/userActions';
import ConnectButton from '../Navbar/components/ConnectButton';
import Button from '../Common/Button';
import {
   loadMinterContract,
   fetchCirculatingSupply,
} from '../../redux/minter/minterActions';
import Spinner from '../Common/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
   Wrapper,
   ContentWrapper,
   ShineCover,
   ContentBox,
   Divider,
   MintButton,
   ButtonWrapper,
   SpinnerWrapper,
   GlassButton,
} from './styles/index.styles';
import config from './config';

const Minter = () => {
   const { t } = useTranslation(['minter']);

   const dispatch = useDispatch();
   const connectedUser = useSelector((state) => state.user);
   const minterState = useSelector((state) => state.minterContract);
   const collectionSupply = useSelector(
      (state) => state.minterContract.circulatingSupply
   );

   const [counter, setCounter] = useState(1);
   const [claimingNft, setClaimingNft] = useState(false);
   const [mintStatus, setMintStatus] = useState(null);

   const isWalletConnected = connectedUser.account !== null ? true : false;

   const {
      CONTRACT_ADDRESS,
      GAS_LIMIT,
      MAX_SUPPLY,
      NETWORK_EXPLORER,
      PUBLIC_SALE_PRICE,
   } = config;

   const handleFetchContract = (e) => {
      e.preventDefault();
      dispatch(loadMinterContract(CONTRACT_ADDRESS));
   };

   const mintNft = (_amount) => {
      if (_amount <= 0) {
         return;
      }
      setClaimingNft(true);
      minterState.contract.methods
         .mint(_amount)
         .send({
            gasLimit: GAS_LIMIT,
            to: CONTRACT_ADDRESS,
            from: connectedUser.account,
            value: minterState.web3.utils.toWei(
               (PUBLIC_SALE_PRICE * _amount).toString(),
               'ether'
            ),
         })
         .on('transactionHash', function (hash) {
            setMintStatus(`Transaction submitted!`);
         })
         .once('error', (err) => {
            setMintStatus('Some error occoured, Please Wait and try again!');
            setClaimingNft(false);
         })
         .then((receipt) => {
            setMintStatus(`Transaction successful!`);
            setTimeout(() => {
               setMintStatus(null);
            }, 10000);
            setClaimingNft(false);
            dispatch(fetchCirculatingSupply());
         });
   };

   const handleCounterIncrement = (e) => {
      e.preventDefault();
      if (MAX_SUPPLY - collectionSupply > 5) {
         setCounter(counter + 1);
      } else {
         if (counter < MAX_SUPPLY - collectionSupply) {
            setCounter(counter + 1);
         }
      }
   };

   return (
      <Container
         id='minter'
         sx={{
            color: '#fff',
            marginBottom: { sm: 2, xs: 1, md: 8 },
            paddingTop: { sm: 3, xs: 3, md: 15 },
         }}
      >
         <Typography
            width='100%'
            color='#666'
            fontWeight='500'
            textAlign='center'
            mb={{ xs: 2, sm: 2, md: 1 }}
         >
            {t('Get it Now')}
         </Typography>
         <Typography
            variant='h3'
            width='100%'
            fontWeight='500'
            textAlign='center'
            mb={{ xs: 3, sm: 3, md: 5 }}
         >
            {t('MINT YOUR NFT')}
         </Typography>

         <Typography
            variant='h5'
            width='100%'
            fontWeight='500'
            textAlign='center'
            mb={{ xs: 3, sm: 3, md: 5 }}
         >
            {t(`Mint price: ${PUBLIC_SALE_PRICE} FTM`)}
         </Typography>
         <Box
            sx={{
               width: '100%',
               height: '100%',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               flexDirection: 'column',
               marginBottom: 10,
            }}
         >
            <Wrapper>
               {connectedUser.loading || minterState.loading ? (
                  <SpinnerWrapper>
                     <Spinner />
                  </SpinnerWrapper>
               ) : (
                  <Fragment>
                     {!isWalletConnected ? (
                        <ButtonWrapper>
                           <ConnectButton />
                        </ButtonWrapper>
                     ) : isWalletConnected && minterState.contract === null ? (
                        <ButtonWrapper>
                           <Button
                              text='Load Contract'
                              handleClick={handleFetchContract}
                           />
                        </ButtonWrapper>
                     ) : isWalletConnected &&
                       minterState.contract &&
                       /* eslint eqeqeq: 0 */
                       collectionSupply != MAX_SUPPLY ? (
                        <Fragment>
                           <ContentWrapper
                              flexDirection='column'
                              alignItems={true}
                           >
                              <ContentWrapper>
                                 <ContentWrapper>
                                    {/* CONTENT BOXES */}
                                    <ShineCover margin={'10px 0px'}>
                                       {minterState.contract === null ? (
                                          <Spinner />
                                       ) : (
                                          <ContentBox fontSize='1rem'>
                                             {collectionSupply ? (
                                                collectionSupply
                                             ) : (
                                                <Spinner />
                                             )}
                                             &nbsp; / {MAX_SUPPLY}
                                          </ContentBox>
                                       )}

                                       <Divider />
                                       <ContentBox>{counter}</ContentBox>
                                    </ShineCover>
                                 </ContentWrapper>

                                 <ContentWrapper
                                    flexDirection={'column'}
                                    padding='5px'
                                 >
                                    {/* MINT AMOUNT BUTTONS */}
                                    <GlassButton
                                       disabled={counter === 5}
                                       onClick={handleCounterIncrement}
                                    >
                                       +
                                    </GlassButton>
                                    <GlassButton
                                       disabled={counter === 0}
                                       onClick={() => {
                                          if (counter > 1)
                                             setCounter(counter - 1);
                                       }}
                                    >
                                       -
                                    </GlassButton>
                                 </ContentWrapper>
                              </ContentWrapper>
                              {/* MINT BUTTON */}
                              <MintButton
                                 active={collectionSupply ? 1 : 0}
                                 disabled={!collectionSupply ? true : false}
                                 onClick={(e) => {
                                    if (!claimingNft) {
                                       e.preventDefault();
                                       mintNft(counter);
                                    } else {
                                       alert(
                                          'You already have a pending transaction. Please check metamask!'
                                       );
                                    }
                                 }}
                              >
                                 {claimingNft ? <Spinner /> : 'MINT'}
                              </MintButton>
                           </ContentWrapper>
                        </Fragment>
                     ) : (
                        <p>THE SALE HAS ENDED</p>
                     )}
                  </Fragment>
               )}

               {mintStatus && (
                  <Typography
                     variant='h6'
                     width='100%'
                     color='#a17230'
                     fontWeight='500'
                     textAlign='center'
                     mb={{ xs: 2, sm: 2, md: 2 }}
                     mt={{ xs: 2, sm: 2, md: 5 }}
                  >
                     {mintStatus}
                  </Typography>
               )}
            </Wrapper>
         </Box>
      </Container>
   );
};

export default Minter;
