import { Suspense, useEffect } from 'react';
import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import { Box } from '@mui/material';
import About from './components/About';
import News from './components/News';
import Minter from './components/Minter';
import RoadMap from './components/RoadMap';
import Team from './components/Team';
import Partners from './components/Partners';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
   const tittleFont = '"Lakki Reddy", cursive';
   const tittleFont2 = "'El Messiri', sans-serif";
   const descFont = 'Josefin Sans';
   const spanTitleFont = 'Inconsolata';
   const secondaryTitleFont = 'Merienda';
   const theme = createTheme({
      typography: {
         title: { fontFamily: tittleFont2 },
         h4: { fontFamily: tittleFont2 },
         h3: { fontFamily: tittleFont2 },
         h5: { fontFamily: secondaryTitleFont },
         h6: { fontFamily: secondaryTitleFont },
         description: {
            fontFamily: descFont,
            fontWeight: '600',
            color: '#AAB2CD',
         },
      },
   });

   useEffect(() => {
      AOS.init();
      AOS.refresh();
   }, []);

   return (
      <>
         <Suspense fallback={null}>
            <div className='App'>
               <ThemeProvider theme={theme}>
                  <Navbar />
                  <Box p={{ sm: 1, xs: 1, md: 2 }} m={{ sm: 0, xs: 0, md: 2 }}>
                     <Home />
                     <About />
                     <News />
                     <Minter />
                     <RoadMap />
                     <Team />
                     <Partners />
                     <ContactUs />
                  </Box>
                  <Footer />
               </ThemeProvider>
            </div>
         </Suspense>
      </>
   );
}

export default App;
