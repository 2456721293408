import jonatan from "../../assets/img/team/Dr. Jonatan Val (CEO).jpg";
import mauro from "../../assets/img/team/Mauro Blanco (CIO).png";
import basshar from "../../assets/img/team/Basshar Okal (CCO).jpeg";
import mohammed from "../../assets/img/team/Dr. Mohamed Al Hemairy (Project advisor).jpg";
import joan from "../../assets/img/team/Joan Cabrero (Project Manager).jpg";
import nicolas from "../../assets/img/team/Nicolás Mesas (COO).jpg";
import raheel from "../../assets/img/team/Raheel Iqbal (CTO).jpg";

const teamList = [
  {
    name: "Dr. Jonatan Val",
    designation: "Founder & Chief Executive Officer",
    src: jonatan,
    about:
      "Dr Jonatan Val holds a PhD degree in environmental sciences and is an expert in paleontology. He has more than 20 years’ experience on the paleontological field and business leadership, during this time he has developed several important museum and theme parks projects of natural history at different locations over the world. Is CEO of Quantum project, and DINOPETREA company, also is president of FACOPS foundation, built to promote the history of life and to preserve its most valuable assets.",
  },
  {
    name: "MR. MAURO BLANCO",
    designation: "Chief Organizing Officer",
    src: mauro,
    about:
      "Entrepreneur. Former professional E-Sports player at an international level for more than 5 years. Multi-disciplinary in the different areas of blockchain services. Leader of several projects within the blockchain in the last 2 years. He is involved in almost all areas of the business, diving deep into product development, business development, and international business relations.",
  },
  {
    name: "MR. NICOLÁS MESAS",
    designation: "Chief Information Officer",
    src: nicolas,
    about:
      "Nicolás is an expert in international relations with more than 10 years of experience in international trade. In his career, he has made business connections with companies in more than 50 countries, having great success in all of his business relationships. In addition, he has been an expert in organizing public trade events for more than 10 years. And he also stands out for his experience of more than 6 years in the exploration and expedition travel guide, which makes him a total expert in travel experiences.",
  },
  {
    name: "MR. BASSHAR OKAL",
    designation: "Chief Communications Officer",
    src: basshar,
    about:
      "Mr. Basshar Okal is an expert in business management and public realtions management. He has a long career working in collaboration with public and private institutions, establishing contact with numerous renowned entities, especially within the Arab world. ",
  },
  {
    name: "MR. JOAN CABRERO",
    designation: "Project manager",
    src: joan,
    about:
      "Former professional player of E. Sports and mtg for more than 6 years. He is the creative vision of Open Games Builders and Blockchain development. He is in charge of the correct development and execution of all our games, virtual platforms and virtual spaces.",
  },
  {
    name: "DR. MOHAMED AL HEMAIRY",
    designation: "Main advisor",
    src: mohammed,
    about:
      "Dr. Mohamed Al Hemairy holds a PhD degree in Software Engineering.He has 17 years of experience in research management, intellectual property, innovation and technology transfer from ideas to products in the United Arab Emirates and the United Kingdom. He helped over a hundred researchers to file more than 120 inventions & managing around 220 patent applications in the largest IP portfolio in UAE. Mohamed has successfully commercialized and negotiated more than 10 Patent License Agreements with advanced Technology and Pharmaceuticals industries in USA, Japan, Denmark, Belgium, and UAE.",
  },
  {
    name: "MR. RAHEEL IQBAL",
    designation: "Chief Technology Partner",
    src: raheel,
    about:
      "Mr. Raheel Iqbal is CEO and Founder of The Game Store Studios, is an expert on the development of games, apps and blockchain, he is focusing on Organization's technology development with respect to the organization's objectives and vision. Establish technical standards for smooth product development in an effective manner.",
  },
];

export default teamList;
